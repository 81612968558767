import React from "react";
import styled from "styled-components";
import { Form, Tooltip } from "antd";
import { ControlOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import GenericStep from "./GenericStep";


const BotsSlideParameters = ({stateBindToDeploymentModel, showTemperature=false, showMaxLength=false, showTopProb=false, showContextHistory=false, showFreqPenality=false, showPresPenality=false, showBestOf=false, cardBackground }) => {
  return (
    <BotsSlideParametersStyled cardBackground={cardBackground}>
      {
        showTemperature &&
        <Form.Item
          className="form-slider"
          label={
            <>
              Temperature{" "}
              <Tooltip
                title={`Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses.
                          Try adjusting temperature or Top P but not both.`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <GenericStep min={0} max={1} step={0.01} name="temperature" trackStyle={"brandTeal"} railStyle={"white"} handleStyle={"brandTeal"} />
        </Form.Item>
      }
      {
        showMaxLength &&
        <Form.Item
          className="form-slider"
          label={
            <>
              Max length (tokens){" "}
              <Tooltip
                title={`Set a limit on the number of tokens per model response. The API supports a maximum of ${stateBindToDeploymentModel.deployment_max_token_limit} tokens
                        shared between the prompt (including system message, examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text.`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <GenericStep name="max_tokens" max={stateBindToDeploymentModel.deployment_max_token_limit} trackStyle={"brandBlue"} railStyle={"white"} handleStyle={"brandBlue"} />
        </Form.Item>
      }
      {
        showTopProb &&
        <Form.Item
          className="form-slider"
          label={
            <>
              Top probabilities
              <Tooltip
                title={`Similar to temperature, this controls randomness but uses a different method. 
                          Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model
                          choose from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both.`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <GenericStep min={0} max={1} step={0.01} name="top_p" trackStyle={"brandGreen"} railStyle={"white"} handleStyle={"brandGreen"} />
        </Form.Item>
      }
      {showContextHistory && stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements && !stateBindToDeploymentModel?.hideFormElements.includes("max_history") && (
        <Form.Item
          className="form-slider"
          label={
            <>
              Context History
              <Tooltip
                title={`Controls number of previos messages passed for setting context. 
                          You can get better results by setting it to maximum, If you get an error of maximum request size, reduce it.`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <GenericStep min={stateBindToDeploymentModel?.context_min_limit || 0} max={stateBindToDeploymentModel?.context_max_limit || 20} name="max_history" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"} />
        </Form.Item>
      )}
      {showFreqPenality && stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements && !stateBindToDeploymentModel?.hideFormElements.includes("frequency_penalty") && (
        <Form.Item
          className="form-slider"
          label={
            <>
              Frequency penalty
              <Tooltip
                title={`Reduce the chance of repeating a token proportionally based on how often it has appeared in the text so far.
                        This decreases the likelihood of repeating the exact same text in a response.`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <GenericStep min={0} max={1} step={0.01} name="frequency_penalty" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"} />
        </Form.Item>
      )}
      {showPresPenality && stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements && !stateBindToDeploymentModel?.hideFormElements.includes("presence_penalty") && (
        <Form.Item
          className="form-slider"
          label={
            <>
              Presence penalty
              <Tooltip
                title={`Reduce the chance of repeating any token that has appeared in the text at all so far.
                        This increases the likelihood of introducing new topics in a response.`}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <GenericStep min={0} max={1} step={0.01} name="presence_penalty" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"} />
        </Form.Item>
      )}
      {showBestOf && stateBindToDeploymentModel && stateBindToDeploymentModel.hideFormElements && !stateBindToDeploymentModel?.hideFormElements.includes("best_of") && (
        <Form.Item
          className="form-slider"
          label={
            <>
              Best of
              <Tooltip
                title={`Generate multiple responses, and display only the one with the best total probability across all its tokens.
                          The unused candidates will still incur usage costs, so use this parameter carefully and make sure to set the parameters for 
                          max response length and ending triggers as well.
                          `}
              >
                <ExclamationCircleOutlined />
              </Tooltip>
            </>
          }
        >
          <GenericStep name="best_of" trackStyle={"brandOrange"} railStyle={"white"} handleStyle={"brandOrange"} />
        </Form.Item>
      )}
    </BotsSlideParametersStyled>
  );
};

const BotsSlideParametersStyled = styled.div`
  padding: 10px 16px 0px;
  .ant-slider-rail{
    background-color: ${(props) => props.cardBackground ? props.theme.colors.backgroundSemiLight: props.theme.colors.white };
  }
  .form-slider:nth-child(4n + 1) {
    .ant-slider-handle {
      &::after,
      &:active::after,
      &:focus::after,
      &:hover::after {
        box-shadow: 0 0 0 4px ${(props) => props.theme.colors.brandTeal};
      }
    }
    .ant-slider-track {
      background-color: ${(props) => props.theme.colors.brandTeal};
    }
  }
  .form-slider:nth-child(4n + 2) {
    .ant-slider-handle {
      &::after,
      &:active::after,
      &:focus::after,
      &:hover::after {
        box-shadow: 0 0 0 4px ${(props) => props.theme.colors.brandBlue};
      }
    }
    .ant-slider-track {
      background-color: ${(props) => props.theme.colors.brandBlue};
    }
  }
  .form-slider:nth-child(4n + 3) {
    .ant-slider-handle {
      &::after,
      &:active::after,
      &:focus::after,
      &:hover::after {
        box-shadow: 0 0 0 4px ${(props) => props.theme.colors.brandGreen};
      }
    }
    .ant-slider-track {
      background-color: ${(props) => props.theme.colors.brandGreen};
    }
  }
  .form-slider:nth-child(4n + 4) {
    .ant-slider-handle {
      &::after,
      &:active::after,
      &:focus::after,
      &:hover::after {
        box-shadow: 0 0 0 4px ${(props) => props.theme.colors.brandOrange};
      }
    }
    .ant-slider-track {
      background-color: ${(props) => props.theme.colors.brandOrange};
    }
  }
`;

export default BotsSlideParameters;
