import { config } from "../../../constants";

const FormattedMimeMessage = ({ message }) => {
  function getFileTypeFromUrl(url) {
    var extension = url.substr(url.lastIndexOf(".") + 1).toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "webp":
        return "image";
      case "mp4":
      case "avi":
      case "mpeg":
      case "wmv":
      case "mpg":
      case "mpegps":
      case "flv":
        return "video";
      case "pdf":
        return "pdf";
      default:
        return "unknown";
    }
  }
  function renderMimeElement(el, i) {
    if (el.startsWith("data:image/")) {
      return <img src={el} alt={`media ${i}`} width="350" height="auto" />;
    } else if (el.startsWith("data:application/pdf")) {
      return <object
      width={"350"}
      height={"auto"}
      data={el}
      type="application/pdf"
    >
      Your browser does not support PDF viewing.
    </object>
      //return <iframe src={el} width="350" height="auto"></iframe>;
    } else if (el.startsWith("data:video/")) {
      return <video width={"350"} height={"auto"} src={el} controls>
        Your browser does not support the video tag.
      </video>;
    } else {
      const fileType = getFileTypeFromUrl(el);
      let uri = config.STORAGE_URI;
      const blobUrl = uri.charAt(uri.length - 1) ==="/"? uri.slice(0,-1): uri;
      switch (fileType) {
        case "image":
          return (
            <img
              max-width={"350px !important"}
              src={`${blobUrl}/images/${el}`}
              alt={`media ${i}`}
            />
          );

        case "video":
          return (
            <video width={"350"} height={"auto"} src={`${blobUrl}/videos/${el}`} controls>
              Your browser does not support the video tag.
            </video>
          );

        case "pdf":
          return (
            <object
              width={"350"}
              height={"auto"}
              data={`${blobUrl}/docs/${el}`}
              type="application/pdf"
            >
              Your browser does not support PDF viewing.
            </object>
          );

        default:
          return <></>;
      }
    }
  }

  if (message) {
    const parts = message.split("#MMM#");
    if (parts.length > 0) {
      //case of multimedia
      return (
        <>
          <p
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "5px",
            }}
          >
            {" "}
            <span>{parts[0]}</span>
            {parts.map((p, i) => (i > 0 ? renderMimeElement(p, i) : <></>))}
          </p>
        </>
      );
    } else {
      //text message
      return message;
    }
  }
};

export default FormattedMimeMessage;
