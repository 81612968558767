import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { RobotOutlined } from "@ant-design/icons";

const HomePageCard = ({ customIconClass, botNameNode, botDecriptionNode, link }) => {
  return (
    <HomePageCardStyled to={link}>
      <span className={`icon ${customIconClass}`}></span>
      <div className="bot-name">{botNameNode}</div>
      <div className="bot-desc">{botDecriptionNode}</div>
    </HomePageCardStyled>
  );
};

const HomePageCardStyled = styled(NavLink)`
  border-radius: 10px;
  border: 1px solid rgba(71, 94, 132, 0.3);
  background: #fff;
  padding: 18px 15px;
  text-decoration: none;
  display: grid;
  grid-template-columns: 30px 1fr 1fr;
  grid-template-rows: 30px 1fr;
  align-items: start;
  gap: 10px;
  transition: 0.3s;
  border-image: ${(props) => `conic-gradient(from ${props.theme.colors.brandBlue}, ${props.theme.colors.brandTeal}, ${props.theme.colors.brandGreen} 0.1turn, ${props.theme.colors.brandOrange} 00.25turn) 30`};
  /* border-image: conic-gradient(from var(--color-1), var(--color-2), var(--color-3) 0.1turn, var(--color-4) 00.25turn) 30; */
  animation: borderRotate var(--d) linear infinite forwards;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(71, 94, 132, 0.3);
  }
  .icon {
    background-position: center;
    background-repeat:no-repeat;
    flex-shrink: 0;
    font-size: 30px;
    width: 30px;
    height: 30px;

    &.hr {
      background-image: url("./icon-hr.png");
      background-size: 94%;
    }
    &.sindri {
      background-image: url("./sindri.png");
      background-size: cover;
    }
    &.hermes {
      background-image: url("./hermes.png");
      background-size: cover;
    }
    &.gpt-playground {
      background-image: url("./icon-bold.svg");
      border:1px solid ${(props) => props.theme.colors.borderColor};
      border-radius: 50%;
      background-size: 70%;
    }
  }
  .bot-desc {
    color: ${(props) => props.theme.colors.brandBlue};
    font-weight: 300;
    grid-column: 1 / 4;
  }
  .bot-name {
    color: inherit;
    font-weight: 700;
    margin-bottom: 10px;
    grid-column: 2 / 4;
  }
  .anticon {
    color: inherit;
  }
  &:nth-child(3) {
    color: ${(props) => props.theme.colors.brandGreen};
  }
  &:nth-child(4) {
    color: ${(props) => props.theme.colors.brandOrange};
  }
  &:nth-child(2) {
    color: ${(props) => props.theme.colors.brandTeal};
  }
  &:nth-child(1) {
    color: ${(props) => props.theme.colors.brandBlue};
  }
`;

export default HomePageCard;
