import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { Layout, Menu, notification, Skeleton, Tooltip, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { defaultParams, getDeploymentByValue } from "../constants";
import UserContext from "../context";
import { createNewChatHistory, getAllChatTitlesForUser, getChatHistoryById, updateChatTitleById, deleteChatHistoryById } from "../service";
import SideBar from "../components/common/SideBar";
import EditableChatName from "../components/GPTPlayground/chat/EditableChatName";
import ChatBuilder from "../components/GPTPlayground/chat/ChatBuilder";
import { defaultMultiModalMessage, getFormDefaultValues } from "../components/GPTPlayground/ChatHelper";
import LayoutContent from "../components/common/LayoutContent";

const { confirm } = Modal;

const GPTPlayground = ({ isLoggedIn, handleLogout }) => {
  const [api, contextHolder] = notification.useNotification();
  const [collapsed, setCollapsed] = useState(false);
  const { userContext, setUserContext } = useContext(UserContext);
  const user = userContext.user;
  const [formInitValues, setFormInitValues] = useState(getFormDefaultValues());
  const [showChatLoader, setShowChatLoader] = useState(true);
  const [navItems, setNavItems] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [promptsList, setPromptsList] = useState(null);
  const [editItemId, setEditItemId] = useState(false);
  const [selectedChatHistoryValue, setSelectedChatHistoryValue] = useState({ chats: [], id: null });
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [fileList, setFileList] = useState([]);
  const [newUserMessage, setNewUserMessage] = useState(defaultMultiModalMessage());
  const [navAddNewLoader, setNavAddNewLoader] = useState(true);
  const [navUpdateLoader, setNavUpdateLoader] = useState(true);

  useEffect(() => {
    if (user && user.name && user.id && navAddNewLoader) {
      setFormInitValues(getFormDefaultValues());
      updateSideMenu(true);
      updateChatbox();
    }
  }, [user, navAddNewLoader]);

  const openNotificationWithIcon = useCallback(({ type, message, description, duration = 8 }) => {
    api[type]({ message, description, duration });
  }, [api]);

  const onNewChatClick = useCallback(async (e, deployment) => {
    setEditItemId([]);
    if (!user) {
      console.log("User is null.");
      return;
    }
    setMessages([]);
    setFileList([]);
    setNavUpdateLoader(true);
    setNewUserMessage(defaultMultiModalMessage());
    const newChatTitle = `new Chat- ${Math.trunc(Math.random() * 100000)}`;
    try {
      const data = await createNewChatHistory(user, newChatTitle, deployment || formInitValues.deployment, defaultParams);
      if (data.id) {
        updateChatbox(data, data.id);
        updateSideMenu(true);
      } else {
        openNotificationWithIcon({
          type: "error",
          message: "Error",
          description: data.message || "Error in starting new chat",
        });
      }
    } finally {
      setNavUpdateLoader(false);
    }
  }, [user, formInitValues.deployment, openNotificationWithIcon]);

  const onChatTitleClick = useCallback(async (chatHistoryId) => {
    if (!user || chatHistoryId <= 0 || (selectedKeys?.length && selectedKeys.includes(chatHistoryId))) return;
    setShowChatLoader(true);
    setNavUpdateLoader(true);
    try {
      const data = await getChatHistoryById(chatHistoryId);
      if (data && !data.code) {
        updateChatbox(data);
        setInput("");
        setFileList([]);
        setNewUserMessage(defaultMultiModalMessage());
      }
    } finally {
      setNavUpdateLoader(false);
    }
  }, [selectedKeys, user]);
  

  const updateChatbox = useCallback((selectedChatHistoryValue, chatHistoryId) => {
    if (selectedChatHistoryValue) {
      const dep = getDeploymentByValue(selectedChatHistoryValue.deployment);
      const values = {
        deployment: selectedChatHistoryValue.deployment,
        deployment_max_token_limit: dep?.max_token_limit,
        ...selectedChatHistoryValue.params[0],
        chats: selectedChatHistoryValue.chats,
        id: selectedChatHistoryValue.id,
        usageInstructions: dep?.usageInstructions,
        deprecate: dep?.deprecate || false,
      };
      setFormInitValues(values);
      setSelectedKeys([selectedChatHistoryValue.id.toString()]);
      setUserContext({ ...userContext, activeChatHistoryId: chatHistoryId });
    } else {
      setFormInitValues(getFormDefaultValues());
    }
  }, [setUserContext, userContext]);

  const updateSideMenu = useCallback(async (refreshClicked = false) => {
    if (refreshClicked && !navUpdateLoader) setNavUpdateLoader(true);
    if (!user || (!refreshClicked && navItems && navItems.length)) {
      setNavUpdateLoader(false);
      return;
    }
    try {
      const data = await getAllChatTitlesForUser(user);
      if (data && !data.code) {
        const uiArray = data.map(item => ({ key: item.id, label: item.name }));
        setNavItems(uiArray);
      }
    } finally {
      setNavUpdateLoader(false);
      setNavAddNewLoader(false);
    }
  }, [user, navItems, navUpdateLoader]);

  const onChatTitleTextUpdate = useCallback(async (chatHistoryId, newTitle) => {
    const resp = await updateChatTitleById(chatHistoryId, newTitle);
    if (!resp.code && resp.id === chatHistoryId) {
      setNavItems(existingItems => existingItems.map(item => (item.key === chatHistoryId ? { ...item, label: newTitle } : item)));
    }
  }, []);

  const onDeleteChatHistoryClick = useCallback((e, chatHistoryId) => {
    e.stopPropagation();
    confirm({
      title: "Confirm delete?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: { type: "default" },
      onOk() {
        deleteChatHistoryById(chatHistoryId).then(() => {
          updateSideMenu(true);
          if (selectedKeys?.length && selectedKeys.includes(String(chatHistoryId))) {
            updateChatbox();
          }
        });
      },
    });
  }, [selectedKeys, updateSideMenu, updateChatbox]);

  return (
    <LayoutStyled>
      {contextHolder}
      <SideBar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        updateChatbox={updateChatbox}
        selectedKeys={selectedKeys}
        updateSideMenu={updateSideMenu}
        setNavItems={setNavItems}
        navItems={navItems}
        promptsList={promptsList}
        setPromptsList={setPromptsList}
        addNewHandler={onNewChatClick}
        showChatLoader={showChatLoader}
        setShowChatLoader={setShowChatLoader}
        setInput={setInput}
        setFileList={setFileList}
        setNewUserMessage={setNewUserMessage}
        showAddButton={true}
      >
        <div className="bottom-nav-container">
          <h3 className="chat-history">Chat History</h3>
          {navAddNewLoader && !collapsed ? (
            <SkeletonStyled active />
          ) : (
            <Menu
              mode="inline"
              defaultSelectedKeys={selectedKeys ? selectedKeys[0] : []}
              selectedKeys={selectedKeys ? selectedKeys[0] : []}
              onClick={(item) => onChatTitleClick(item.key)}
              className="sub-nav-list"
              disabled={navUpdateLoader}
              items={navItems?.map(({ label, key }) => ({
                key,
                label: (
                  <>
                    <Tooltip title={label} placement="right">
                      <span className="nav-label">{label}</span>
                    </Tooltip>
                    <EditableChatName
                      editItemId={editItemId}
                      setEditItemId={setEditItemId}
                      targetId={key}
                      defaultValue={label}
                      onChatTitleTextUpdateFunc={onChatTitleTextUpdate}
                      onDeleteChatHistoryClick={onDeleteChatHistoryClick}
                    />
                  </>
                ),
              }))}
            />
          )}
        </div>
      </SideBar>
      <LayoutContent isLoggedIn={isLoggedIn} handleLogout={handleLogout}>
        <ChatBuilder
          showChatLoader={showChatLoader}
          setShowChatLoader={setShowChatLoader}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          navUpdateLoader={navUpdateLoader}
          setNavUpdateLoader={setNavUpdateLoader}
          navItems={navItems}
          setNavItems={setNavItems}
          messages={messages}
          setMessages={setMessages}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          formInitValues={formInitValues}
          setFormInitValues={setFormInitValues}
          input={input}
          setInput={setInput}
          fileList={fileList}
          setFileList={setFileList}
          newUserMessage={newUserMessage}
          setNewUserMessage={setNewUserMessage}
          selectedChatHistoryValue={selectedChatHistoryValue}
          setSelectedChatHistoryValue={setSelectedChatHistoryValue}
          openNotificationWithIcon={openNotificationWithIcon}
          onNewChatClick={onNewChatClick}
        />
      </LayoutContent>
    </LayoutStyled>
  );
};

const LayoutStyled = styled(Layout)`
  height: 100vh;
  .ant-layout-sider-collapsed {
    .top-nav-container {
      padding-right: 0px;
    }
    .ant-menu-item,
    .chat-history {
      opacity: 0;
      visibility: hidden;
    }
    .ant-menu,
    .bottom-nav-container {
      overflow: hidden;
    }
  }
  .ant-input:focus {
    box-shadow: none;
    outline: none;
  }
  input,
  textarea {
    &::placeholder {
      color: ${(props) => props.theme.colors.placeHolder};
    }
  }
  & * {
    &::-webkit-scrollbar-track {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: rgba(0,0,0,0.3); */
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;
const SkeletonStyled = styled(Skeleton)`
  padding: 10px;
`;

export default GPTPlayground;
