import React, { useContext } from "react";
import styled from "styled-components";
import UserContext from "../../context";
import SuggestionCard from "./SuggestionCard";
import { BoldlogoStyle, Hellotext } from "../Styles";

const NewChatBox = ({suggestedCardHandle, disabled, botNameNode, suggestedInputs}) => {

  const {userContext} = useContext(UserContext)
  const user = userContext.user;

  return (
    <NewChatBoxStyled disabled={disabled}>
      <h1>
        <Hellotext>Hello {user && user.name.split(' ')[0]}.</Hellotext>
        <br />I am {botNameNode}.
      </h1>
      <div className="prompt-suggestion-cards-container">
        {
          suggestedInputs.map((item, index) =>
            <SuggestionCard key={index} item={item} suggestedCardClick={()=>suggestedCardHandle(item, index)} />
          )
        }
      </div>
    </NewChatBoxStyled>
  );
};

const NewChatBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  min-height: 280px;
  height: 100%;
  max-width: 750px;
  margin: 0 auto;
  pointer-events: ${props=>props.disabled && "none"};
  & h1 {
    color: rgba(87, 108, 141, 0.5);
    font-size: 42px;
    font-weight: 500;
    line-height: 1.2;
  }
  .prompt-suggestion-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 180px), 1fr));
    gap: 10px;
  }
`;

export default NewChatBox;
