import React, { useRef } from "react";
import styled from "styled-components";

const PromptText = ({promptInput, setPromptInput, promptTTCContent, setPromptTTCContent}) => {
  const contentRef = useRef(null);
  const handleInput = (e) => {
    setPromptInput(e.target.value)
  };

  return (
    <PromptTextStyled>
      <label htmlFor="text-input">Prompt Text</label>
      <div className="text-input">
        <span className="prompt-span" onChange={handleInput} contentEditable="true" spellCheck="false" ref={contentRef} dangerouslySetInnerHTML={{ __html: promptInput }}>
        </span>
        <span onChange={e=>setPromptTTCContent(e.target.value)}>{promptTTCContent}</span>
      </div>
    </PromptTextStyled>
  );
};

const PromptTextStyled = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 10px;
  box-shadow: none;
  gap: 10px;
  padding: 0;
  position: relative;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  label {
    background: ${(props) => props.theme.colors.bodyBg};
    position: absolute;
    font-size: 1.4rem;
    left: 20px;
    top: -13px;
    padding: 0 5px;
  }
  .text-input{
    outline: none;
    box-shadow: none;
    border: 0;
    font-size: 1.4rem;
    margin: 10px;
    padding: 0;
    &>span{
      display: block;
    }
    .prompt-span {
      opacity: 0.7;
      &:hover, &:focus-visible{
        border: 0;
        outline: none;
      }
    }
    .stp-span2 {
        font-style: italic;
    }
  }
`;

export default PromptText;
