import React, { useState } from "react";
import { InputNumber, Slider, Row, Col, Form, Input, Space } from "antd";
import styled, { withTheme } from 'styled-components';
const GenericStep = ({ name, max = 20, min = 0, step = 1}) => {
  const [inputValue, setInputValue] = useState(1);
  const onChange = (value) => {
    if(isNaN(value)) {
        return;
    }
    setInputValue(value);
  };
  return (
    <GenericInputGroupStyled>
      <Form.Item name={name}>
        <Slider
          min={min}
          max={max}
          onChange={onChange}
          value={typeof inputValue === "number" ? inputValue : 0}
          step={step}
        />
      </Form.Item>
      <Form.Item name={name}>
        <InputNumber
          min={min}
          max={max}
          value={inputValue}
          onChange={onChange}
          step={step}
        />
      </Form.Item>
    </GenericInputGroupStyled>
  );
};

const GenericInputGroupStyled = styled(Space.Compact)`
  display: grid;
  grid-template-columns: 1fr 60px;
  gap: 10px;
`


export default withTheme(GenericStep);


