import { createGlobalStyle } from "styled-components";
import Theme from "./Theme";

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}
h4{
  font-size: 1.4rem;
}
h5{
  font-size: 1.2rem;
}
body {
  background-color: ${(props) => props.theme.colors.bodyBg};
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts};
  font-size: 1.6rem;
  margin: 0;
  .ant-modal-confirm-confirm{
    .ant-modal-confirm-body>.anticon{
      color: ${(props) => props.theme.colors.brandOrange};
    }
    .ant-btn-default:not(.ant-btn-dangerous){
      &:hover{
        border-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
}

  ol, ul {
    list-style: none;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
  
  a, a:hover{
    color: inherit;
    text-decoration: none;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

export default GlobalStyles;
