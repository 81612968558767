import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserOutlined, HomeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Dropdown, Space, Avatar, Button, Tooltip } from "antd";
import styled from "styled-components";
import { getUserProfile } from "../../service";
import UserContext from "../../context";

const Header = ({ isLoggedIn, handleLogout, homeVisible = true, newChat = false, OnNewChatHandler}) => {

  const isGPTPlayground = window.location.hostname.includes("gpt-playground");
  
  const items = [
    {
      label: "Logout",
      key: "1",
    },
  ];
 
  const {userContext, setUserContext} = useContext(UserContext)
  const user = userContext.user;
  useEffect(() => {
    if (isLoggedIn) {
      getUserProfile()
        .then((_user) => {
          if (!_user) {
            handleLogout();
          }
          setUserContext({...userContext, user: _user})
          //setUser(user);
        })
        .catch((err) => {
          console.log("Authentication failed", err.code);
          //setUser(null);
          setUserContext({...userContext, user: null})
          handleLogout();
        });
    }
  }, [isLoggedIn]);
  return (
    user && (
      <HeaderStyled>
        <ChatNavigationStyled>
          {(homeVisible && !isGPTPlayground) &&
          <NavLinkStyled to="/">
            <Tooltip title="Go to Home" placement="right"><HomeOutlined /></Tooltip>
          </NavLinkStyled>
          }
          {
            newChat && <Tooltip title="New Chat" placement="right"><PlusCircleOutlined onClick={OnNewChatHandler}/></Tooltip>
          }
        </ChatNavigationStyled>
        <DropdownUI
          menu={{
            items,
            onClick: (e) => {
              switch (e.key) {
                case "1": {
                  //setUser(null);
                  setUserContext({...userContext, user: null})
                  handleLogout();
                  break;
                }
              }
            },
          }}
          trigger={["click"]}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
            }} className="avatar-wrapper" shape={"circle"}
            
          >
            {
              user?.picture ? <img src={user.picture} />
              :<Avatar icon={<UserOutlined />} />
            }
            
          </Button>
        </DropdownUI>
      </HeaderStyled>
    )
  );
};

const HeaderStyled = styled.header`
  background: transparent;
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  padding: 0 10px;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.bodyBg};
  z-index: 2;

  .anticon{
    font-size: 24px;
  }

  .avatar-wrapper{
    width: 32px;
    height: 32px;
    margin: 8px;
    overflow: hidden;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .avatar-wrapper > *{
    border: none;
  }
`
const DropdownUI = styled(Dropdown)`
  cursor: pointer;
  .ant-avatar.ant-avatar-icon{
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
const ChatNavigationStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const NavLinkStyled = styled(NavLink)`
  height: 40px;
  width:40px;
  transition: background-color 0.2s;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.colors.buttonHover};
  }
`


export default Header;
