import React, { useRef, useState, useEffect } from "react";
import { MessageContainerStyled, ScrollButton } from "../Styles";
import styled from "styled-components";
import { ArrowDownOutlined } from "@ant-design/icons";

const MessageContainer = ({ children }) => {
  const wrapperRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const handleScroll = () => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const isScrollable = wrapper.scrollHeight > wrapper.clientHeight;
      const isAtBottom =
        Math.abs(wrapper.scrollHeight - wrapper.scrollTop - wrapper.clientHeight) < 1;
      setIsButtonVisible(isScrollable && !isAtBottom);
    }
  };

  const scrollToBottom = () => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.scrollTo({
        top: wrapper.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const observer = new MutationObserver(() => handleScroll());
      observer.observe(wrapper, { childList: true, subtree: true });
      handleScroll(); // Initial check

      wrapper.addEventListener("scroll", handleScroll);
      return () => {
        wrapper.removeEventListener("scroll", handleScroll);
        observer.disconnect();
      };
    }
  }, [isButtonVisible]);

  return (
    <MessageContainerStyled ref={wrapperRef}>
      <ScrollButton onClick={scrollToBottom} isVisible={isButtonVisible}>
        <ArrowDownOutlined />
      </ScrollButton>
      {children}
    </MessageContainerStyled>
  );
};

export default MessageContainer;
