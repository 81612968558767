import React, { useState, useRef, useCallback, useEffect } from "react";
import { Form, notification } from "antd";
import LayoutContent from "../components/common/LayoutContent";
import { getFormDefaultValues } from "../components/GPTPlayground/ChatHelper";
import NewChatBox from "../components/common/NewChatBox";
import ChatsBox from "../components/GPTPlayground/chat/ChatsBox";
import InputPromptBox from "../components/common/InputPromptBox";
import HelperButtonController from "../components/common/HelperButtonController";
import { BoldlogoStyle, BottomContainerStyled, FormStyled, LayoutStyled } from "../components/Styles";
import BotsSlideParameters from "../components/common/BotsSlideParameters";
import { rawChatCompletions } from "../service/sindri.service";
import styled from "styled-components";
import MessageContainer from "../components/common/MessageContainer";
import { useSpeechRecognition } from "../hooks/useSpeechRecognition";

const suggestedInputs = [
  "What is the recommended version of .Net to write an API?",
  "Where can I get .Net boilerplate from bitbucket?",
  "How to make my application docker compatible?",
  "Is there a JS boilerplate here?"
];

const openNotificationWithIcon = (api, { type, message, description, duration = 8 }) => {
  api[type]({ message, description, duration });
};

const Sindri = ({ isLoggedIn, handleLogout }) => {
  const [api, contextHolder] = notification.useNotification();
  const [formInitValues, setFormInitValues] = useState(getFormDefaultValues());
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [chatContextHistory, setChatContextHistory] = useState([]);
  const [form] = Form.useForm();
  const chatBoxRef = useRef(null);
  const inputPromptRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openParameter, setOpenParameter] = useState(false);
  const [stateBindToDeploymentModel, setStateBindToDeploymentModel] = useState({ deployment: null, hideFormElements: [] });
  const { isListening, transcript, setIsListening, error } = useSpeechRecognition();
  const sendMessage = useCallback(async (content) => {
    content = content.trim();
    if (content === "") {
      openNotificationWithIcon(api, {
        type: "error",
        message: "Invalid or Empty Prompt",
        description: "Write Something in the prompt input box",
      });
      return;
    }

    setInput("");
    setLoading(true);
    const displayDate = new Date().toLocaleString('en-IN', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    const userMessage = {
      role: "user",
      content,
      timeStamp: displayDate,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      const data = await rawChatCompletions(content, chatContextHistory.length ? chatContextHistory : null);

      if (data?.ResponseText) {
        const assistantMessage = {
          role: "assistant",
          content: data.ResponseText,
          timeStamp: displayDate,
          HelpUrls: data.HelpUrls || [],
        };

        setChatContextHistory((prevHistory) => [
          ...prevHistory,
          userMessage,
          assistantMessage,
        ]);

        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [api, chatContextHistory]);

  const handleInputChange = useCallback((e) => {
    setInput(e.target.value);
  }, []);

  const handleKeyPress = useCallback((event) => {
    if (!event.shiftKey && event.key === "Enter" && !stateBindToDeploymentModel.deprecate) {
      event.preventDefault();
      sendMessage(input);
    }
  }, [input, sendMessage, stateBindToDeploymentModel.deprecate]);

  const handleResetChatButton = useCallback(() => {
    setMessages([]);
    setChatContextHistory([]);
  }, []);

  useEffect(() => {
      if (transcript) {
      setInput(transcript); // Automatically send the transcribed message
      }
  }, [transcript]);

  return (
    <LayoutStyled>
      {contextHolder}
      <LayoutContent 
        isLoggedIn={isLoggedIn} 
        handleLogout={handleLogout} 
        OnNewChatHandler={handleResetChatButton}
        newChat={!!messages.length}
      >
        <FormStyled layout="vertical" form={form} name="basic" initialValues={formInitValues}>
          <MessageContainer avatarStyle={{backgroundImage: 'url("../sindri.png")', backgroundSize: 'cover'}}>
            {!messages.length ? (
              <NewChatBox 
                sendMessage={sendMessage} 
                suggestedCardHandle={sendMessage} 
                disabled={false} 
                suggestedInputs={suggestedInputs}
                botNameNode={<>Sindri, <BoldlogoStyle>BOLD</BoldlogoStyle>'s Confluence Bot</>}
              />
            ) : (
              <ChatsBox 
                messages={messages} 
                loading={loading} 
                setInput={setInput} 
                inputPromptRef={inputPromptRef} 
                openNotificationWithIcon={(params) => openNotificationWithIcon(api, params)} 
                showCopyForAnswer={true}
              />
            )}
          </MessageContainer>
          <BottomContainerStyled customstyle={{paddingBottom: "20px"}}>
            <InputPromptBox
              handleInputChange={handleInputChange}
              input={input}
              handleKeyPress={handleKeyPress}
              setOpenParameter={setOpenParameter}
              openParameter={openParameter}
              handleSendMessage={() => sendMessage(input)}
              inputPromptRef={inputPromptRef}
              handleVoiceNote={() => setIsListening(!isListening)}
              isListening = {isListening}
            />
          </BottomContainerStyled>
          <HelperButtonController 
            setOpenParameter={setOpenParameter} 
            openParameter={openParameter} 
            iconNode={"?"} 
            cardBackground={"#fff"}
            titleNode={<>Parameters</>}
          >
            <BotsSlideParameters 
              stateBindToDeploymentModel={stateBindToDeploymentModel}
              showContextHistory={true}
              cardBackground={true}
            />
          </HelperButtonController>
        </FormStyled>
      </LayoutContent>
    </LayoutStyled>
  );
};

export default Sindri;