import { Button, Card, Tooltip } from "antd";
import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const HelperButtonController = (props) => {
  const { iconNode, openParameter, setOpenParameter, titleNode, setHrChatBotMenuSelectKey, cardBackground, parentButtonRef, HrChatBotMenuSelectKey = "0" } = props
  const parameterCardRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        parameterCardRef.current &&
        !parameterCardRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        (!parentButtonRef || !parentButtonRef.current.contains(event.target))
      ) {
        setOpenParameter(false);
      }
    };
  
    if (openParameter) {
      document.addEventListener("click", handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openParameter]);
  

  return (
    <HelperStyled ref={parameterCardRef}>
      {
        openParameter &&
        <HelperBodyStyled title={titleNode} cardbackground={cardBackground} ismenuopended={HrChatBotMenuSelectKey}>
          {props.children}
        </HelperBodyStyled>
      }
      <Tooltip title={"GPT Parameters"} placement="left">
        <Button ref={buttonRef} icon={iconNode} shape="circle" className="parameter-button" onClick={() => setOpenParameter(!openParameter)}></Button>
      </Tooltip>
    </HelperStyled>
  );
};

const HelperStyled = styled.div`
.parameter-button{
    position: fixed;
    bottom: 10px;
    right: 10px;
    span{
      /* transform: rotate(90deg); */
    }
  }
`

const HelperBodyStyled = styled(Card)`
  position: fixed;
  width: ${props => props.ismenuopended ? "370px": "260px"};
  right: 15px;
  bottom: 55px;
  max-height: 85%;
  overflow-y: auto;
  z-index: 2;
  background-color: ${props => props.cardbackground || props.theme.colors.backgroundSemiLight};
  box-shadow: 0px 4px 20px 0px ${props => props.theme.colors.boxShadowLight};
  border-radius: ${props => props.theme.borderCardRadiusLG};
  border: 1px solid ${props => props.theme.colors.borderSemiLight};
  .ant-menu-light{
    background: unset;
    & li{
      /* padding: 0px; */
    }
  }
  .ant-card-head {
    border-bottom: none;
    min-height: unset;
    padding: 6px 16px 0px;
    .ant-card-head-title{
      font-size: 18px;
      color: #000;
      font-weight: 600;
    }
  }
  .ant-card-body{
    padding: 0px;
    .helper-heading{
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      margin-left: -10px;
      &>h4{
        font-size: 20px;
        margin-left: 10px;
      }
      &.parameter-heading{
        padding: 10px 16px;
      }
    }
    & .ant-form-item{
        margin-bottom: 12px;
        & * {
          color: #000;
        }
        .ant-form-item-label{
          padding-bottom: 0px;
          vertical-align: middle;
          span{
            margin-left: 5px;
          }
        }
        & .ant-form-item{
            margin-bottom: 0px;
        }
        &.single-input .ant-form-item-label{
          padding-bottom: 5px;
        }
      .ant-checkbox-inner{
        width: ${(props) => props.theme.checkBoxWidth};
        height: ${(props) => props.theme.checkBoxHeight};
        margin-top: 0px;
        &:after{
          inset-inline-start: 32%;
        }
      }
    }
  }
  .ant-input-number{
    width: 60px;
    box-shadow: none;
    outline: none;
    border: none;
    &.ant-input-number-compact-last-item{
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.colors.borderColor};
      &:hover, &:focus, &:focus-within{
        border-color: ${(props) => props.theme.colors.primary};
      }
    }
  }
  .ant-input, .ant-input-number-input{
    &:focus{
      box-shadow: none;
      outline: none;
    }
  }
`;



export default HelperButtonController;
