import React, { useState } from "react";
import styled from "styled-components";
import { MenuFoldOutlined, MenuUnfoldOutlined, PlusCircleOutlined, DeleteOutlined, RobotOutlined, CodeOutlined } from "@ant-design/icons";
import { Layout, Button, Skeleton, Tooltip, Menu } from "antd";

const { Sider } = Layout;

const SideBar = (props) => {
  const {
    collapsed,
    setCollapsed,
    highlitedText,
    selectedKey,
    navItems,
    navUpdateLoader,
    addNewHandler,
    navAddNewLoader,
    onNavClickHandler,
    secondaryNavItems = [],
    highlitedSecondayText = "Empty",
    showAddButton = false,
    navDeleteHandler,
    selectedMenu,
    setSelectedMenu
  } = props;


  return (
    <SiderUI trigger={null} collapsible collapsed={collapsed} width={260} collapsedWidth={100}>
      <div className="top-nav-container">
        <Tooltip title={"Collapse History"} placement="right">
          <Button color="primary" type="text" icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={() => setCollapsed(!collapsed)} />
        </Tooltip>
        {
          showAddButton &&
          <Tooltip title={"New Prompt"} placement="right">
            <Button type="text" icon={<PlusCircleOutlined />} onClick={addNewHandler} disabled={navUpdateLoader || navAddNewLoader} shape="circle"></Button>
          </Tooltip>
        }
      </div>
      {props.children}
    </SiderUI>
  );
};

const SiderUI = styled(Sider)`
  overflow: hidden;
  background-color: ${(props) => (!props.collapsed ? props.theme.colors.backgroundSemiLight : props.theme.colors.bodyBg)} !important;
  .ant-btn {
    transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  & .ant-menu {
    background-color: transparent;
    &.ant-menu-light {
      & a{
        transition: none;
      }
      .ant-menu-item {
        padding-inline: 10px 2px !important;
      }
      .ant-menu-item-selected{
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};
      }
      
    }
    &.ant-menu-light.ant-menu-root.ant-menu-vertical {
      border: none;
    }
    &.ant-menu-inline {
      border-inline-end: none;
    }
    &:not(:hover)::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &.ant-menu-light.sub-nav-list {
      .ant-menu-item {
        padding-inline: 10px 2px !important;
        .edit-icon-wrapper {
          display: none;
        }
        &:hover {
          .edit-icon-wrapper {
            display: block;
          }
        }

        .action-icon:hover {
            background-color: ${(props) => props.theme.colors.white};
          }
        .action-icon.delete {
          :hover {
            color: ${(props) => props.theme.colors.buttonDanger};
          }
        }
        
        &.ant-menu-item-selected{

          .action-icon {
            color: ${(props) => props.theme.colors.white};
          }
          .edit-icon-wrapper{
            margin-top: 2px;
          }
          .edit-icon-wrapper,
          .edit-wrapper {
            display: flex;
            .action-icon {
              
              &:hover {
                background-color: ${(props) => props.theme.colors.bodyBg};
              }
              &.edit {
                :hover {
                  color: ${(props) => props.theme.colors.primary};
                }
              }
              &.delete {
                :hover {
                  /* background-color: ${(props) => props.theme.colors.buttonDangerHoverBg}; */
                  color: ${(props) => props.theme.colors.buttonDanger};
                }
              }
            }
          }
        }
        &.ant-menu-item-disabled{
          .edit-icon-wrapper, .edit-wrapper{
            display: none;
          }
        }
        .ant-menu-title-content {
          display: flex;
          position: relative;
          justify-content: space-between;
        }
      }
    }
  }

  .nav-label {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .top-nav-container {
    display: grid;
    grid-template-columns: 1fr 40px;
    padding: 10px;
    gap: 10px;
    /* transition: all 0.1s cubic-bezier(0.2, 0, 0, 1); */
    .ant-btn {
      font-size: 21px;
      line-height: 37px;
      height: 40px;
      width: 40px;
    }
    .ant-menu{
      grid-column: 1/3;
    }
  }
  .ant-layout-sider-children{
    display: flex;
    flex-direction: column;
  }
  .bottom-nav-container{
    max-height: 100%;
    flex: 1 1 auto;
    position: relative;
    overflow-y: auto;
    .chat-history{
      position: sticky;
      top: 0;
      background-color: ${(props) => props.theme.colors.backgroundSemiLight};
      z-index: 1;
    }
  }
  .chat-history {
    padding: 10px 15px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.dark};
    opacity: 1;
    font-size: 16px;
    visibility: visible;
  }
`;



export default SideBar;
