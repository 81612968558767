const Theme = {
  colors: {
    primary: "#475F85",
    textPrimary: "#475F85",
    textSecondary: "#c0c2c4",
    bodyBg: "#F8FBFF",
    white: "#FFF",
    dark: "#000",
    darkTransparent: "#000000d9",
    buttonHeight: "34px",
    brandTeal: "#007581", // these colors name are being used on diffrent pages, so chages it wisely
    brandBlue: "#475F85",
    brandGreen: "#7EA640",
    brandOrange: "#F8991C",
    backgroundSemiLight: "#E5ECF6",
    borderSemiLight: "rgba(71, 94, 132, 0.5)",
    boxShadowLight: "rgba(71, 94, 132, 0.3)",
    brandBlueLight: "#475F85e7",
    borderColor: "rgba(87, 108, 141, 0.50)",
    placeHolder: "rgba(87, 108, 141, 0.5)",
    pageFontColor: "rgba(71, 94, 132, 1)",
    buttonHover: "rgba(71, 94, 132, 0.1)",
    buttonDanger: "#ff4d4f",
    buttonDangerHoverBg: "#fff2f0",
  },
  fonts: `system-ui, sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  fontSize: 16,
  borderRadius: 3,
  inputPaddingBlock: "0.6rem",
  lineHeight: 1.7,
  controlHeight: 32,
  controlHeightLG: 36,
  fontSizeIcon: 12,
  borderRadiusLG: 5,
  sizePopupArrow: 16,
  borderCardRadiusLG: "10px",
  checkBoxWidth: "30px",
  checkBoxHeight: "30px"
};
export const ThemeToken = {
  colorPrimary: Theme.colors.primary, // Affecting Areas, Checkbox Check, Navbar Active Color
  // colorTextBase: Theme.colors.textPrimary,// Affecting Areas, Normal Text onChat, on Side bar, On Parameter Button better to have colorText
  fontFamily: Theme.fonts,
  fontSize: Theme.fontSize,
  lineHeight: Theme.lineHeight,
  sizePopupArrow: Theme.sizePopupArrow,
  controlHeight: Theme.controlHeight, // Affeting Area, Buttons, Input  Height
  controlHeightLG: Theme.controlHeightLG, // Affeting Area, SideNav List Height
  fontSizeIcon: Theme.fontSizeIcon, // Affeting Area, Select Drop icon
  colorIconHover: Theme.colors.brandGreen,
  colorBgBase: Theme.colors.white, //Affetcing Areas, Input and Default Button Background 
  colorFillTertiary: Theme.colors.white, //Affetcing Slider ant-slider-rail background
  colorBgContainerDisabled: Theme.colors.buttonHover, //Affetcing diabled backgrounds
  colorBgTextHover: Theme.colors.buttonHover, //Affecting Area, Sidebar Navlist Hover Background
  controlItemBgHover: Theme.colors.buttonHover, // Affecting Areas, Select Option hovers
  colorLink: Theme.colors.primary,
  // colorPrimaryBg: Theme.colors.buttonHover, // Affecting Areas, Sidebar Navlist Active Background

  colorTextPlaceholder: Theme.colors.placeHolder, // No Effect
  borderRadiusLG: Theme.borderRadiusLG,

  colorPrimaryHover: Theme.colors.primary, // Affected Area, on hover of inputs border color, checkbox checked color, button border and text color
  
  colorBorder: Theme.colors.borderColor, // Affected Area, inputs border, button border
  colorPrimaryActive: Theme.colors.primary, // OnClick of button color of it changes
  colorBgTextActive: Theme.colors.buttonHover, // OnClick of button background changes
  colorText: Theme.colors.primary, // Affecting Area, Entire Page
  controlItemBgActive: Theme.colors.buttonHover, // //Affecting Area, Sidebar Navlist and Select Background
  itemSelectedColor: Theme.colors.white, // No Effects
  colorPrimaryTextActive: Theme.colors.brandOrange, // No Effects
  colorItemTextSelected: Theme.colors.white, // No Effects
  itemSelectedBg: Theme.colors.brandGreen, // No Effects
  colorTextSecondary: Theme.colors.brandGreen,
  colorBgLayout: Theme.colors.bodyBg,
  colorWarning: Theme.colors.brandOrange,
  colorWarningText: Theme.colors.brandOrange,
  colorInfoTextHover: Theme.colors.buttonHover,
  colorError: Theme.colors.brandOrange,
  colorErrorText: Theme.colors.brandOrange,
  colorHighlight: Theme.colors.brandOrange,
}

// export const componentsToken = {
//   Layout: {
//     siderBg: Theme.colors.brandOrange,
//     headerBg: Theme.colors.brandOrange
//   }
// }
export default Theme;