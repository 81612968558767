import React from "react";
import styled from "styled-components";
import { Form, Select } from "antd";


const {Option} = Select;

const SelectGPTEnv = ({onSelectionChange, selectItrator}) => {
  return (
    <SelectGPTEnvStyled>
      <Form.Item name="deployment">
        <Select onChange={onSelectionChange} type="default">
          {selectItrator.map(
            (opt) =>
              opt.useForChat && (
                <Option
                  key={opt.value}
                  value={opt.value}
                  //label = {opt.text}
                  data-max_token_limit={opt.max_token_limit}
                >
                  {opt.text}
                </Option>
              )
          )}
        </Select>
      </Form.Item>
    </SelectGPTEnvStyled>
  );
};

const SelectGPTEnvStyled = styled.div`
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  .ant-form-item {
    margin-left: auto;
    margin-bottom: 5px;
    max-width: 250px;
  }
  .ant-select-selector {
    &,
    &:focus,
    &:active {
      padding: 0px !important;
      border: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
    .ant-select-selection-item {
      text-align: right;
      padding-right: 35px;
      background-color: ${(props) => props.theme.colors.bodyBg};
      outline: none;
      border: none !important;
      box-shadow: none;
    }
  }
  .ant-select-arrow {
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

export default SelectGPTEnv;
