import { useState, useEffect } from "react";

export const useSpeechRecognition = () => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      setError("Speech Recognition is not supported in this browser.");
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    const handleStart = () => setIsListening(true);
    const handleEnd = () => setIsListening(false);
    const handleError = (e) => setError(e.error);

    const handleResult = (event) => {
      const speechResult = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");
      setTranscript(speechResult);
    };

    if (isListening) {
      recognition.start();
    }

    recognition.addEventListener("start", handleStart);
    recognition.addEventListener("end", handleEnd);
    recognition.addEventListener("error", handleError);
    recognition.addEventListener("result", handleResult);

    return () => {
      recognition.removeEventListener("start", handleStart);
      recognition.removeEventListener("end", handleEnd);
      recognition.removeEventListener("error", handleError);
      recognition.removeEventListener("result", handleResult);
      recognition.stop();
    };
  }, [isListening]);

  return { isListening, setIsListening, transcript, error };
};
