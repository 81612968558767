import React from 'react'
import styled from 'styled-components';

const SuggestionCard = ({item, suggestedCardClick}) => {
  return (
    <SuggestionCardStyled onClick={suggestedCardClick}>{item}</SuggestionCardStyled>
  )
}

const SuggestionCardStyled = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  background: #e5ecf6;
  cursor: pointer;
  padding: 8px 10px;
  transition: 0.3s;
  text-wrap: balance;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(71, 94, 132, 0.3);
  }
`;

export default SuggestionCard