import React from "react";
import { Avatar, Skeleton } from "antd";
import { UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Header from "../header/Header";


const LayoutContent = (props) => {
    const { isLoggedIn, handleLogout, homeVisible, newChat, OnNewChatHandler } = props;
  return (
    <LayoutContentStyled >
        <Header 
            isLoggedIn={isLoggedIn}
            handleLogout={handleLogout}
            homeVisible={homeVisible}
            newChat={newChat}
            OnNewChatHandler={OnNewChatHandler}
        />
        <MainStyled>
            {props.children}
        </MainStyled>
    </LayoutContentStyled>
  );
};

const LayoutContentStyled = styled.section`
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative; 
    width: 100%;
    overflow: hidden;
`
const MainStyled = styled.main`
    display: flex;
    flex: auto;
    height: calc(100% - 60px);
`

export default LayoutContent;
