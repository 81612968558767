import React, { useContext } from "react";
import styled from "styled-components";
import UserContext from "../context";
import { NavLink } from "react-router-dom";
import LayoutContent from "../components/common/LayoutContent";
import { BoldlogoStyle, Hellotext } from "../components/Styles";
import HomePageCard from "../components/common/HomePageCard"

const Home = ({ isLoggedIn, handleLogout }) => {
  const { userContext } = useContext(UserContext);
  const user = userContext.user;
  let isQa =  window.location.hostname.includes("qa-") || window.location.hostname.includes("localhost");

  return (
        <LayoutContent
          isLoggedIn={isLoggedIn} 
          handleLogout={handleLogout} 
          homeVisible = {false}
        >
          <NewChatBoxStyled>
            <>
              <h1 className="hello-text">
                <Hellotext>Hello {user && user.name.split(" ")[0]}.</Hellotext>
                <br />Welcome to <BoldlogoStyle>BOLD</BoldlogoStyle> Bots!
              </h1>
              <p className="choose">Choose the Bot you want to access:</p>
            </>
            <div className="prompt-suggestion-cards-container">
              {isQa && <HomePageCard
                botNameNode={`HR ChatBot Buddy`}
                botDecriptionNode={`One place solution for all your HR policies. Applicable to Employees of BOLD Technology Systems Pvt. Ltd. ("BOLD-INDIA")`}
                link={"hr-policies"}
                customIconClass="hr"
              />}
              <HomePageCard
                botNameNode={`Sindri`}
                botDecriptionNode={`BOLD's Confluence Bot.`}
                link={"confluence-arch"}
                customIconClass="sindri"
              />
              <HomePageCard
                botNameNode={`Hermes`}
                botDecriptionNode={`Meaningful TTC using LLM.`}
                link={"chatbot-ttc"}
                customIconClass="hermes"
              />
              <HomePageCard
                botNameNode={`GPT Play-ground`}
                botDecriptionNode={`Your in house GPT Chat bot.`}
                link={"gpt-playground"}
                customIconClass="gpt-playground"
              />
            </div>
          </NewChatBoxStyled>
    </LayoutContent>
  );
};

const NewChatBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: center;
  width: 100%;
  min-height: 280px;
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;
  overflow-y: auto;
  overflow: visible;
  padding: 0 15px;


  @media (max-height: 500px) {
    padding-bottom: 20px;
  }

  & h1 {
    color: ${(props) => props.theme.colors.borderColor};
    font-size: 56px;
    font-weight: 500;
    line-height: 1.2;
  }
  & .choose {
    color: ${(props) => props.theme.colors.brandBlue};
    font-size: 38px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .prompt-suggestion-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
    gap: 10px;
    margin-top: 50px;
  }
`;

export default Home;
