import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageMeta = ({ title, favicon }) => {
  const location = useLocation();

  useEffect(() => {
    // Update the document title
    if (title) {
      document.title = title;
    }

    // Update the favicon
    if (favicon) {
      const link =
        document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = favicon;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }, [title, favicon, location.pathname]);

  return null;
};

export default PageMeta;
