import Cookies from "js-cookie";

const HERMES_API_ENDPOINT = "https://chat.bold.com/api/v1/de/vectors/ttc/query";

const authToken = () => `Bearer ${Cookies.get("auth_user")}`;


//#region chat interface result
async function rawChatCompletions(input, promptInput, TTCSection) {
  //example requestJson
  //{"messages":[{"role":"user","content":"answer questions about india"}],"temperature":0.7,"top_p":0.95,"frequency_penalty":0,"presence_penalty":0,"max_tokens":800,"stop":null}

  const rawAPIPayLoad = {
    llm_model_params: {
      "Temperature": 0.8,
      "MaxTokens": 2000,
      "StopSequences": [
        "###"
      ],
      "TopProbabilities": 0.5,
      "PresencePenalty": null,
      "FrequencyPenalty": null,
      "N": 1,
      "BestOf": 1
    },
    llm_model_system: "AzureOpenAI",
    section_code: TTCSection,
    static_text: promptInput,
    vector_search_prompt: input
  };
  
  const res = await fetch(HERMES_API_ENDPOINT, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(rawAPIPayLoad),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
  });
  let data = await res.json();
  return data;
}
//#endregion chat completions endpoint


export {rawChatCompletions}