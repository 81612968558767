import React from "react";
import { Checkbox, Col, Form, Input, Row, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

const BotsInputParameter = () => {
  return (
    <BotsInputParameterStyled>
      <Form.Item
        label={
          <>
            Stop sequences{" "}
            <Tooltip
              title={`Make responses stop at a desired point, such as the end of a sentence or list.
                    Specify up to four sequences(comma seperated) where the model will stop generating further tokens in a response.
                    The returned text will not contain the stop sequence.`}
            >
              <ExclamationCircleOutlined />
            </Tooltip>
          </>
        }
        name="stop"
      >
        <Input placeholder="Comma seperated stop words" />
      </Form.Item>
      <Form.Item
        className="single-input"
        label={
          <>
            Pre-response text
            <Tooltip title={`Insert text after the user’s input and before the model’s response. This can help prepare the model for a response.`}>
              <ExclamationCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Row gap={10}>
          <Col span={2}>
            <Form.Item name="pre_text_check" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={21} push={1}>
            <Form.Item name="pre_text">
              <Input placeholder="Response prefix text" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        className="single-input"
        label={
          <>
            Post-response text
            <Tooltip title={`Insert text after the model’s generated response to encourage further user input, as when modeling a conversation.`}>
              <ExclamationCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Row>
          <Col span={2}>
            <Form.Item name="post_text_check" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={21} push={1}>
            <Form.Item name="post_text">
              <Input placeholder="Response postfix text" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </BotsInputParameterStyled>
  );
};

const BotsInputParameterStyled = styled.div`
  padding: 0px 16px 10px;
`

export default BotsInputParameter;
