import React, { useState, useRef, useContext, useEffect } from "react";
import { Button, Form, Menu, notification, Skeleton } from "antd";
import styled from "styled-components";
import LayoutContent from "../components/common/LayoutContent";
import { getFormDefaultValues } from "../components/GPTPlayground/ChatHelper";
import NewChatBox from "../components/common/NewChatBox";
import ChatsBox from "../components/GPTPlayground/chat/ChatsBox";
import InputPromptBox from "../components/common/InputPromptBox";
import HelperButtonController from "../components/common/HelperButtonController";
import HelperContentList from "../components/common/HelperContentList";
import { BottomContainerStyled, FormStyled, LayoutStyled, MessageContainerStyled } from "../components/Styles";
import BotsSlideParameters from "../components/common/BotsSlideParameters";
import { rawChatCompletions } from "../service/hrchatbot.service";
import MessageContainer from "../components/common/MessageContainer";
import { useSpeechRecognition } from "../hooks/useSpeechRecognition";

const HRChatBotBuddy = ({ isLoggedIn, handleLogout }) => {
  const [api, contextHolder] = notification.useNotification();
  const [formInitValues, setFormInitValues] = useState(getFormDefaultValues());


  const [selectedChatHistoryValue, setSelectedChatHistoryValue] = useState({ chats: [], id: null });
  const [messages, setMessages] = useState(selectedChatHistoryValue?.chats || []);
  const [input, setInput] = useState("");
  const [chatContextHistory, setChatContextHistory] = useState([]);

  const [form] = Form.useForm();
  const chatBoxRef = useRef(null);
  const inputPromptRef = useRef(null);
  const helperButtonRef = useRef(null)
  const [loading, setLoading] = useState(false);
  const [openParameter, setOpenParameter] = useState(false);
  const [stateBindToDeploymentModel, setStateBindToDeploymentModel] = useState({ deployment: null, hideFormElements: [], context_min_limit: 0, context_max_limit: 6 });
  const [HrChatBotMenuSelectKey, setHrChatBotMenuSelectKey] = useState(null);
  const { isListening, transcript, setIsListening, error } = useSpeechRecognition();
  const HrChatBotMenu = [
    {key: 0, label: "Help",},
    {key: 1, label: "Disclaimer !!"},
    // {key: 2, label: "Parameters"},
  ];

  const disclaimerContent = [
    `Before using Buddy (the "Chatbot") from Bold Technology Systems Private Limited ("we," "us," "our," or "BOLD"), please carefully read this disclaimer. Our AI Chatbot is an automated system exclusively designed to provide general information and assistance on BOLD’s Employee Handbook to Bold’s employees(“User”). The use of Chatbot is at your own risk, and we shall not be held responsible for any liability arising from its use.`,
    `Although the AI Chatbot aims to offer accurate and helpful responses, its accuracy may not always be 100%. If you discover any inaccuracies in the information provided by the Chatbot, kindly notify the HR Team at hrindia@bold.com so that your concern may be addressed appropriately.`,
    `By using our Chatbot, you acknowledge and agree to the terms mentioned herein or as may be introduced from time to time.`
  ]

  const helpContent = [
    `Hello, My Name is HR Chatbot - Buddy, and I am Bold's own virtual HR. I'm here to address all your questions regarding Bold's employee handbook. I am created in-house so I am one of my kind 😁.`,
    `In version 1, I can only answer one question without earlier history or context. Therefore, make sure your questions are as detailed as you can.`
  ]

  const suggestedInputs = [
    "Tell me about the Leave policy here?",
    "What is the certification policy, and how can I claim the reimbursement?",
    "What is the Team outing budget?",
    "Employee Wellness Program and Eligibility?"
  ]

  const onSelectChatBotMenu = ({key, domEvent}) =>{
    domEvent.stopPropagation();
    setHrChatBotMenuSelectKey(key)
  }


  const openNotificationWithIcon = ({ type, message, description, duration = 8 }) => {
    api[type]({ message, description, duration });
  };

  const sendMessage = async (content) => {
    content = content.trim();
    if (!content) {
      openNotificationWithIcon({
        type: "error",
        message: "Invalid or Empty Prompt",
        description: "Please write something in the prompt input box.",
      });
      return;
    }

    setInput("");
    setLoading(true);
    const displayDate = new Date().toLocaleString('en-IN', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    const userMessage = {
      role: "user",
      content,
      timeStamp: displayDate,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      const data = await rawChatCompletions(content, chatContextHistory.length ? chatContextHistory : null);
      if (data?.ResponseText) {
        const assistantMessage = {
          role: "assistant",
          content: data.ResponseText,
          timeStamp: displayDate,
        };

        setChatContextHistory((prevHistory) => [
          ...prevHistory,
          userMessage,
          assistantMessage,
        ]);

        setMessages((prevMessages) => [
          ...prevMessages,
          assistantMessage,
        ]);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (!event.shiftKey && event.key === "Enter" && !stateBindToDeploymentModel.deprecate) {
      event.preventDefault();
      sendMessage(input);
    }
  };

  const handleHelperControllerBox = (e) => {
    setOpenParameter(!openParameter);
    setHrChatBotMenuSelectKey("1");
  }
  const handleBackButtton = (e) => {
    e.stopPropagation();
    setHrChatBotMenuSelectKey(null);
  }
  const handleResetChatButton = (e) => {
    setMessages([])
    setChatContextHistory([])
  }
  
  const Disclaimer = () => (
    <StyledDisclaimer>
      Disclaimer: This is applicable only to "BOLD-India" employees. Further by using HR Chatbot Buddy, you acknowledge potential inaccuracies.
      <Button 
        type="link" 
        onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();  
          handleHelperControllerBox(e);
        }} 
        ref={helperButtonRef}
      >
        Click here
      </Button>
      to read complete Disclaimer.
    </StyledDisclaimer>
  );

  useEffect(() => {
      if (transcript) {
      setInput(transcript); // Automatically send the transcribed message
      }
  }, [transcript]);
  
  return (
    <LayoutStyled>
      {contextHolder}
      <LayoutContent 
        isLoggedIn={isLoggedIn} 
        handleLogout={handleLogout} 
        OnNewChatHandler = {handleResetChatButton}
        newChat={messages.length ? true : false} 
      >
        <FormStyled layout="vertical" form={form} name="basic" initialValues={formInitValues}>
          <MessageContainer>
            {
              !messages.length ? (
                <NewChatBox 
                  sendMessage={sendMessage} 
                  suggestedCardHandle={sendMessage} 
                  disabled={false} 
                  suggestedInputs={suggestedInputs}
                  botNameNode={<>HR Chatbot Buddy</>} />
              ) : (
                <ChatsBox 
                  messages={messages} 
                  loading={loading} 
                  setInput={setInput} 
                  inputPromptRef={inputPromptRef} 
                  isDisclaimer = {true}
                  disClaimer = {<Disclaimer />}
                />
              ) // Chat Box
            }
          </MessageContainer>
          <BottomContainerStyled>
            <InputPromptBox
              handleInputChange={handleInputChange}
              input={input}
              handleKeyPress={handleKeyPress}
              setOpenParameter={setOpenParameter}
              openParameter={openParameter}
              handleSendMessage={()=>sendMessage(input)}
              inputPromptRef={inputPromptRef}
              handleVoiceNote={() => setIsListening(!isListening)}
              isListening = {isListening}
              isMic = {false}
            />
            <div className="help-text-wrapper">
              <Disclaimer />
            </div>
          </BottomContainerStyled>
          <HelperButtonController 
            setHrChatBotMenuSelectKey={setHrChatBotMenuSelectKey} 
            HrChatBotMenuSelectKey={HrChatBotMenuSelectKey}
            cardBackground={"#fff"}
            setOpenParameter={setOpenParameter} 
            openParameter={openParameter} 
            parentButtonRef={helperButtonRef}
            iconNode={"?"} titleNode={null}>
            {HrChatBotMenuSelectKey === null && <Menu items={HrChatBotMenu} onClick={onSelectChatBotMenu} />}
            {
              HrChatBotMenuSelectKey === "0" &&
              <HelperContentList
                listContent={helpContent}
                titleNode={HrChatBotMenuSelectKey && HrChatBotMenu[HrChatBotMenuSelectKey].label}
                handleBackButtton={handleBackButtton}
              />
            }
            {
              HrChatBotMenuSelectKey === "1" &&
              <HelperContentList
                activeKey={"1"}
                listContent={disclaimerContent}
                currentKey={HrChatBotMenuSelectKey}
                titleNode={HrChatBotMenuSelectKey && HrChatBotMenu[HrChatBotMenuSelectKey].label}
                handleBackButtton={handleBackButtton}
              />
            }

            {/* {
              HrChatBotMenuSelectKey === "2" &&
              <>
                <div className="helper-heading parameter-heading">
                  {
                    <Button
                      type="text"
                      onClick={handleBackButtton}
                      icon={<ArrowLeftOutlined />}
                    ></Button>
                  }
                  <h4>{HrChatBotMenuSelectKey && HrChatBotMenu[HrChatBotMenuSelectKey].label}</h4>
                </div>
                <BotsSlideParameters 
                  stateBindToDeploymentModel={stateBindToDeploymentModel}
                  showContextHistory={true}
                />
              </>
            } */}
          </HelperButtonController>
        </FormStyled>
      </LayoutContent>
    </LayoutStyled>
  );
};

const StyledDisclaimer = styled.strong`
  .ant-btn{
      padding: 0px 5px 0 0;
      color: ${props => props.theme.colors.brandOrange};

      &:hover {
        color: ${props => props.theme.colors.brandOrange};
      }
    }
`;

export default HRChatBotBuddy;
