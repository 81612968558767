import { Form, Layout } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  min-width: ${(props) => props.minWidth || "1150px"};
  min-height: 46px;
  padding: ${(props) => props.padding || "0 15px"};
  width: 100%;
`;

export const ContentWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-top: 15px;
  min-height: 250px;
  position: relative;
`;

export const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: ${(props) => props.top || 0};
  right: 0;
  bottom: 0;
`;

export const Hellotext = styled.span`
  background: ${(props) => `linear-gradient(90deg, 
              ${props.theme.colors.brandOrange} 0%, 
              ${props.theme.colors.brandGreen} 25%, 
              ${props.theme.colors.brandTeal} 50%, 
              ${props.theme.colors.brandBlue})`};
  background-clip: text;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const BoldlogoStyle = styled.strong`
  background: ${(props) => `linear-gradient(to right, 
                ${props.theme.colors.brandBlue} 25%, 
                ${props.theme.colors.brandTeal} 25% 52%, 
                ${props.theme.colors.brandGreen} 52% 75%, 
                ${props.theme.colors.brandOrange} 75% 100%)`};
  background-clip: text;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-sizing: border-box;
`;
export const LayoutStyled = styled(Layout)`
  height: 100vh;
  .ant-layout-sider-collapsed {
    .top-nav-container {
      padding-right: 0px;
    }
    .ant-menu-item,
    .chat-history {
      opacity: 0;
      visibility: hidden;
    }
    .ant-menu,
    .bottom-nav-container {
      overflow: hidden;
    }
  }
  .ant-input:focus {
    box-shadow: none;
    outline: none;
  }
  input,
  textarea {
    &::placeholder {
      color: ${(props) => props.theme.colors.placeHolder};
    }
  }
  & * {
    &::-webkit-scrollbar-track {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: rgba(0,0,0,0.3); */
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const FormStyled = styled(Form)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.2s linear;
  @media (max-width: 1024px) {
    & > * {
      padding: 0 10px;
    }
  }
`;
export const MessageContainerStyled = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  div>.left .avatar{
    ${props=> props.avatarStyle}
  }
`;
export const BottomContainerStyled = styled.div`
  flex: 0 0 auto;
  flex-direction: column;
  display: flex;
  padding-bottom: 5px;
  gap: 10px;

  .help-text-wrapper{
    width: 100%;
    max-width: 750px;
    margin: 10px auto 0px;
    font-size: 1.4rem;
    line-height: 1;
  }
`;

export const ScrollButton = styled.button`
  position: sticky;
  top: 88%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.brandBlue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  font-size: 1.6rem;
  height: 40px;
  width: 40px;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s, visibility 0.3s;
  flex: 0 0  auto;
`;