import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { ArrowLeftOutlined } from "@ant-design/icons";

const HelperContentList = ({listContent, titleNode, handleBackButtton}) => {
  return (
    <HelperContentListStyled>
      <div className='helper-heading'>
        {<Button type="text" onClick={handleBackButtton} icon={<ArrowLeftOutlined />}></Button>}
        <h4>{titleNode}</h4>
      </div>
      <ul>
        {listContent.map((ele, index) => (
          <li key={index}>{ele}</li>
        ))}
      </ul>
    </HelperContentListStyled>
  )
}

const HelperContentListStyled = styled.div`
  padding: 10px 16px;
  ul{
    list-style: decimal;
    padding-left: 20px;
  }
  
`

export default HelperContentList