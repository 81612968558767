import React, { useState, useEffect } from "react";
import {PlayCircleOutlined, PauseCircleOutlined, SoundOutlined} from "@ant-design/icons";
import styled from "styled-components";
import { Button } from "antd";

const TextToSpeech = ({ id, text, activeId, setActiveId, className }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  // Start speaking the provided text
  const startSpeaking = () => {
    if ("speechSynthesis" in window) {
      // Cancel any ongoing speech
      window.speechSynthesis.cancel();
  
      setTimeout(() => {
        // Notify parent about the active message
        setActiveId(id);
  
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.onend = () => {
          setIsSpeaking(false);
          setIsPaused(false);
          setActiveId(null); // Reset activeId when playback ends
        };
        utterance.onerror = () => {
          setIsSpeaking(false);
          setIsPaused(false);
          setActiveId(null); // Reset on error
        };
        
        setIsSpeaking(true);
        setIsPaused(false);
        window.speechSynthesis.speak(utterance);
      }, 200); // Delay to ensure cancelation takes effect
    } else {
      alert("Your browser does not support Text-to-Speech.");
    }
  };
  

  // Pause or resume speech
  const togglePauseResume = () => {
    if (isSpeaking) {
      if (isPaused) {
        window.speechSynthesis.resume();
        setIsPaused(false);
      } else {
        window.speechSynthesis.pause();
        setIsPaused(true);
      }
    }
  };

  // Stop speaking if the component becomes inactive
  useEffect(() => {
    if (activeId !== id && isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setIsPaused(false);
    }
  }, [activeId, id, isSpeaking]);

  // Stop speaking when the component unmounts
  useEffect(() => {
    return () => {
      if (isSpeaking) {
        window.speechSynthesis.cancel();
        setIsSpeaking(false);
        setIsPaused(false);
      }
    };
  }, [isSpeaking]);

  return (
    <ButtonStyled
      type="text"
      className={className}
      onClick={isSpeaking ? togglePauseResume : startSpeaking}
    >
      {isSpeaking ? (
        isPaused ? (
          <PlayCircleOutlined /> // Play icon
        ) : (
          <PauseCircleOutlined /> // Pause icon
        )
      ) : (
        <SoundOutlined /> // Speaker icon
      )}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
font-size: 1.8rem;
  padding: 0;
  width: 32px;
`;
export default TextToSpeech;
