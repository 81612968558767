import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Button, Input, Spin } from "antd";

const EditableChatName = ({ targetId, defaultValue, onChatTitleTextUpdateFunc, onDeleteChatHistoryClick, editItemId, setEditItemId }) => {
  const inputBoxRef = useRef();
  const inputContRef = useRef()
  const [editItemName, setEditItemName] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [input, setInput] = useState(defaultValue);

  useEffect(() => {
    if (editItemName) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      !editItemName && document.removeEventListener('click', handleClickOutside);
    };
  }, [editItemName]);

  useEffect(()=>{
    if(editItemId && editItemId.includes(targetId)){
      setEditItemName(true)
    }
    else if(editItemId && !editItemId.includes(targetId)){
      setEditItemName(false)
    }
  }, [editItemId])

  const handleClickOutside = (event) => {
    if (inputContRef.current && !inputContRef.current.contains(event.target)) {
      setEditItemName(false);
      setEditItemId([])
    }
  };

  const antIcon = (
    <LoadingOutlined
      spin
    />
  );


  useEffect(() => {
    inputBoxRef?.current?.focus({
      cursor: 'end',
    });
    inputBoxRef?.current?.select();
  }, [editItemName]);

  const onEditItem = (e) => {
    e.stopPropagation();
    setEditItemName(true);
    setEditItemId([targetId])
    return false;
  }

  const onCloseEditItem = (e) => {
    e.stopPropagation();
    setEditItemName(false);  
    setEditItemId([])
    setInput(defaultValue);
    return false;
  }

  const onTextChange = (e) => {
    e.stopPropagation();
    if (e.key === "Enter"){
      onEditConfirmationClick(e)
      return
    }
    setInput(e.target.value);
  }

  const onEditConfirmationClick = async (e) => {
    e.stopPropagation();
    if(input.trim() === ''){
      inputBoxRef?.current?.focus();
      return
    }
    setShowLoader(true)
    if (input != ''){      
      await onChatTitleTextUpdateFunc(targetId, input);
    }
    setShowLoader(false)
    setEditItemName(false);
    return false;
  }



  return (
    <EditableChatNameStyled>
    {!editItemName ?
        <div className="edit-icon-wrapper">
          <Button
            type="text"
            className="action-icon edit"
            onClick={(e) => onEditItem(e)}
            icon={<EditOutlined /> }
          >
          </Button>
          <Button
            type="text"
            className="action-icon delete" 
            onClick={(e) => onDeleteChatHistoryClick(e, targetId)}
            icon={<DeleteOutlined />}
          >
          </Button>
        </div>
        :
        <div className="edit-wrapper" ref={inputContRef}>
          <Input defaultValue={input} onClick={(e)=> {e.preventDefault(); e.stopPropagation()}} onChange={(e) => onTextChange(e)} onKeyDown={(e) => onTextChange(e)} ref={inputBoxRef} />
          <Button
            type="text"
            className="action-icon edit"
            onClick={onEditConfirmationClick}
            icon={!showLoader ? <CheckOutlined /> : <Spin indicator={antIcon} />}
          >
          </Button>
          <Button
            type="text"
            className="action-icon delete"
            onClick={onCloseEditItem}
            icon={<CloseOutlined />  }
          >
          </Button>
        </div>
      }
    </EditableChatNameStyled>
  );
};


const EditableChatNameStyled = styled.div`
  /* display: flex; */
  flex: 0 0 64px;
  .edit-wrapper{
    align-items: center;
    display: flex;
    position: absolute;
    top: 2px;
    left: 0px;
    right: 0px;
    button{
      /* min-width: 32px; */
      flex: 0 0 32px;
      &.edit{
        margin-left: 3px;
      }
    }
  }
  .ant-spin{
    color: ${props => props.theme.colors.primary};
    background-color: transparent;
  }

  .ant-input {
    height: 30px;
    padding-left: 5px;
  }
`

export default EditableChatName;